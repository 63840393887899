import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery/gallery"
import Companys from "../components/companys"
import pakmaya from "../assets/img/pakmaya-digital-marketing.png"
import tcdd from "../assets/img/tcdd-digital-marketing.png"
import ecanta from "../assets/img/ecanta-digital-marketing.png"
import yolcu from "../assets/img/yolcu-360-digital-marketing.png"
import kb from "../assets/img/kb-digital-pazarlama.png"
import stm from "../assets/img/stm-digital-pazarlama.png"
import liva from "../assets/img/liva-basari.jpg"
import fulla from "../assets/img/fulla-basari.jpg"

import { Link } from "gatsby"



const Referances = () => (
    <Layout>
        <SEO title="Markalar" description="Sunduğumuz hizmetler ve özel stratejilerin yansımalarını deneyimleyen müşterilerimizden bazılarına ait istatistikler." />
        <div className="refrances container">
            <div className="referances-head">
                <h1>Gerçek başarı hikayeleri.</h1>
                <p>Sunduğumuz hizmetler ve özel stratejilerin yansımalarını deneyimleyen müşterilerimizden bazılarına ait istatistikler.</p>
            </div>
    
            <div className="row referances-markalar py60">
                
                <div className="item col-md-6">
                <Link to="/basari-hikayeleri/liva-pastacilik/"><img src={liva} /></Link>
                    <p className="small">Digital Marketing</p>
                    <Link to="/basari-hikayeleri/liva-pastacilik/">Liva Pastacılık</Link>
                    <p>Liva Pastacılık’ın şu anda ulaştığı aylık sipariş adeti, sıfır noktasından başlatılan dijital operasyon sayesinde yalnızca Ankara ili içinde 3000’den fazla.</p>
                    <div className="item-bottom">
                        <p className="bold">+90k</p>
                        <p className="small">Aylık edinilen organik trafik</p>
                    </div>
                    <div className="item-bottom">
                        <p className="bold">4,500</p>
                        <p className="small">'den fazla aylık sipariş adeti</p>
                    </div>
                </div>
                <div className="item col-md-6">
                <Link to="/basari-hikayeleri/stm/"><img src={stm} /></Link>
                    <p className="small">Digital Marketing</p>
                    <Link to="/basari-hikayeleri/stm/">STM</Link>
                    <p>Flatart, STM markasının marka bilinirliğinin güçlü şekilde aktarılması için 360° dijital pazarlama hizmeti vermektedir. </p>
                    <div className="item-bottom">
                        <p className="bold">+%22</p>
                        <p className="small">Organik Trafik Artışı</p>
                    </div>
                    <div className="item-bottom">
                        <p className="bold">+%27.7</p>
                        <p className="small">Takipçi Kazanımı</p>
                    </div>
                </div>
            </div>
            <div className="hero" style={{marginTop:-30, marginBottom:50}}> 
                <a href="/basari-hikayeleri/"> <button  className="red-button">Tüm Başarı Hikayeleri</button> </a> 
            </div>


        </div>
    
        <div className="our-clients container">
            <h2>Hizmet verdiğimiz markalar.</h2>
            <Companys />
        </div>

        <div className="py60">
        <Gallery name="all" />
        </div>
        

        


    </Layout>
)

export default Referances
